import { thunk } from "easy-peasy";
import {
  addPartRequestApiCall,
  addStockItemRequestApiCall,
  addUnserviceablePartRequestApiCall,
  addUnserviceableStockItemApiCall,
  createPartRequestManifestPdfApiCall,
  createUnserviceablePartPdfApiCall,
  createsPartsRequisitionPdfApiCall,
  getPartRequestManifestApiCall,
  getPartRequestsApiCall,
  searchPartsApiCall,
} from "../../api/part";
import { sumQtyAvail } from "../../utilities/part";
import {
  OPERATION_ADD_PART_REQUEST_NAME,
  OPERATION_ADD_STOCK_ITEM_REQUEST_NAME,
  OPERATION_ADD_UNSERVICEABLE_PART_REQUEST_NAME,
  OPERATION_ADD_UNSERVICEABLE_STOCK_ITEM_NAME,
  PAGE_PARTS_REQUEST,
} from "../../utilities/variables";
import { handleAcumaticaError } from "../../utilities/error";
import moment from "moment";
import { openAndDownloadBase64FileOnWeb } from "../../utilities/document";
import { Platform } from "react-native";

const thunks = {
  getPartRequestsThunk: thunk(async (actions, navigation, helpers) => {
    actions.setLoadingAction(true);

    try {
      const {
        task: {
          currentTask: {
            ProjectTaskIDInt,
            CustomerWarehouse,
            CompanyWarehouse,
          },
        },
        status: { hasWifi },
      } = helpers.getState();

      if (hasWifi) {
        const partRequestList = await getPartRequestsApiCall({
          ProjectTaskIDInt: ProjectTaskIDInt.value,
          CustomerWarehouse: CustomerWarehouse.value,
          CompanyWarehouse: CompanyWarehouse.value,
        });

        actions.setPartRequestListAction(partRequestList);
      }

      navigation && navigation.navigate(PAGE_PARTS_REQUEST);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  printPartRequestManifestPdfThunk: thunk(
    async (actions, partRequest, helpers) => {
      actions.setLoadingAction(true);

      try {
        const {
          task: { currentTask },
          status: { hasWifi },
        } = helpers.getState();

        if (hasWifi) {
          const base64PdfString = await createPartRequestManifestPdfApiCall({
            WorkOrderID: currentTask.WorkOrderID.value,
            ProjectTaskID: currentTask.ProjectTaskID.value,
            ProjectDescription: currentTask.ProjectDescription.value,
            ProjectTaskDescription: currentTask.ProjectTaskDescription.value,
            EquipID: currentTask.EquipID.value,
            Customer: currentTask.Customer.value,
            partsRequestList: await getPartRequestManifestApiCall(
              currentTask.ProjectTaskID.value
            ),
          });

          if (Platform.OS === "web") {
            openAndDownloadBase64FileOnWeb(base64PdfString);
          } else {
            actions.setFileBase64Action(base64PdfString);
            actions.showFileModalAction(true);
          }
        } else {
          actions.setAlertThunk({
            message: "Cannot print part request manifest offline.",
            type: "error",
          });
        }
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          type: "error",
          message: handleAcumaticaError(err),
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  printPartsRequisitionPdfThunk: thunk(
    async (actions, partRequest, helpers) => {
      actions.setLoadingAction(true);

      try {
        const {
          task: { currentTask },
          status: { hasWifi },
        } = helpers.getState();

        if (hasWifi) {
          const base64PdfString = await createsPartsRequisitionPdfApiCall({
            ...partRequest,
            ProjectDescription: currentTask.ProjectDescription.value,
            ProjectTaskDescription: currentTask.ProjectTaskDescription.value,
          });

          if (Platform.OS === "web") {
            openAndDownloadBase64FileOnWeb(base64PdfString);
          } else {
            actions.setFileBase64Action(base64PdfString);
            actions.showFileModalAction(true);
          }
        } else {
          actions.setAlertThunk({
            message: "Cannot print parts requisition offline.",
            type: "error",
          });
        }
      } catch (err) {
        console.error(err);
        actions.setAlertThunk({
          type: "error",
          message: handleAcumaticaError(err),
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  searchPartsThunk: thunk(async (actions, searchValue, helpers) => {
    actions.setLoadingAction(true);

    try {
      const {
        task: { currentTask },
        part: { searchInput, stockItems },
        status: { hasWifi },
      } = helpers.getState();

      if (hasWifi) {
        const stockItems = await searchPartsApiCall({
          searchValue: searchValue || searchInput,
          cust: currentTask.CustomerWarehouse.value,
          comp: currentTask.CompanyWarehouse.value,
        });

        if (!stockItems.length) {
          actions.setAlertThunk({
            message: "No Items Found",
            type: "warning",
          });
          actions.showPartSearchResultsModalAction(true);
        }

        actions.setStockItemsAction(stockItems.map((p) => sumQtyAvail(p)));
        actions.showPartSearchResultsModalAction(true);
      } else if (stockItems.length) {
        actions.showPartSearchResultsModalAction(true);
      } else {
        actions.setAlertThunk({
          message:
            "Cannot find previously searched parts for this task and cannot search parts offline",
          type: "error",
        });
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        message: handleAcumaticaError(err),
        type: "error",
      });
    }

    actions.setLoadingAction(false);
  }),
  addUnserviceablePartRequestThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const {
        part: { unserviceablePartInput, chosenStockItem },
        task: { currentTask },
        status: { hasWifi },
        employee: { scannedEmployee },
      } = helpers.getState();

      if (
        currentTask.unserviceablePartsLocationOverride &&
        (!currentTask.UnserviceableWarehouse.value ||
          currentTask.UnserviceableWarehouse.value === "NOWAREHOUSE" ||
          !currentTask.UnserviceableLocation.value ||
          currentTask.UnserviceableLocation.value === "NOLOCATION")
      ) {
        throw new Error("No Unserviceable Part Warehouse or Location found.");
      } else {
        const data = {
          ...unserviceablePartInput,
          PartNbr: chosenStockItem.InventoryID,
          ReasonforRemoval: `${unserviceablePartInput.ReasonforRemoval} - Position: ${unserviceablePartInput.Position}`,
          Inspector: scannedEmployee.AccountID,
          Warehouse: currentTask.unserviceablePartsLocationOverride
            ? currentTask.UnserviceableWarehouse.value
            : currentTask.WarehouseID.value,
          Location: currentTask.unserviceablePartsLocationOverride
            ? currentTask.UnserviceableLocation.value
            : currentTask.LocationID.value,
          ProjectID: currentTask.WorkOrderID.value,
          Task: currentTask.ProjectTaskID.value,
          QuoteNbr: currentTask.ProjectTaskID.value,
        };

        if (hasWifi) {
          const createdUnserviceablePart =
            await addUnserviceablePartRequestApiCall(data);

          if (createdUnserviceablePart.QuoteNbr.value) {
            actions.setAlertThunk({
              message: `Unserviceable Part Request: ${createdUnserviceablePart.QuoteNbr.value} created successfully`,
              type: "success",
            });

            if (currentTask.printNonRoutineTaskCard) {
              const base64PdfString = await createUnserviceablePartPdfApiCall({
                ...data,
                PartNbr: chosenStockItem.InventoryCD,
                Inspector:
                  scannedEmployee.Inspector === "YES"
                    ? scannedEmployee.LegalName
                    : "",
                EquipID: currentTask.EquipID.value,
                Date: moment(data.Date).format("L"),
                AcumaticaUSPNumber: createdUnserviceablePart.QuoteNbr.value,
              });

              if (Platform.OS === "web") {
                openAndDownloadBase64FileOnWeb(base64PdfString);
              } else {
                actions.setFileBase64Action(base64PdfString);
                actions.showFileModalAction(true);
              }
            }
          }
        } else {
          actions.addPendingOperationAction({
            ...data,
            operationName: OPERATION_ADD_UNSERVICEABLE_PART_REQUEST_NAME,
            operationLabel: `Add Unserviceable Part Request for Part ${chosenStockItem.InventoryCD} for Task ${currentTask.ProjectTaskID.value}`,
            operationDescription: `Add Part ${chosenStockItem.InventoryCD} to Unserviceable Part Request for Task ${currentTask.ProjectTaskID.value}`,
          });
          actions.setAlertThunk({
            message: `Add unserviceable part request for Part ${chosenStockItem.InventoryCD} for Task ${currentTask.ProjectTaskID.value} is now pending. You will need to sync this app once you are online again.`,
            type: "success",
          });
        }

        actions.resetUnserviceablePartInputAction();
        actions.showAddUnserviceablePartRequestModalAction(false);
      }
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  addPartRequestThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const {
      part: { partQuantityInput, chosenStockItem },
      task: { currentTask },
      status: { hasWifi },
      employee: { scannedEmployee },
    } = helpers.getState();

    const data = {
      ...partQuantityInput,
      InventoryID: chosenStockItem.InventoryCD,
      Description: chosenStockItem.Description,
      Uom: chosenStockItem.BaseUnit,
      ReqType: "I",
      TaskID: currentTask.ProjectTaskID.value,
      WorkOrderID: currentTask.WorkOrderID.value,
      RequestedBy: scannedEmployee.AccountID,
      EmployeeCD: scannedEmployee.AcctCD,
      CustWHSE: currentTask.CustomerWarehouse.value,
      CompWHSE: currentTask.CompanyWarehouse.value,
      TaskIDInt: currentTask.ProjectTaskIDInt.value,
    };
    currentTask.UsrZoneID &&
      currentTask.UsrZoneID.value &&
      (data.UsrZoneID = currentTask.UsrZoneID.value);

    try {
      if (hasWifi) {
        const partRequestList = await addPartRequestApiCall(data);
        actions.setPartRequestListAction(partRequestList);
      } else {
        actions.addPendingOperationAction({
          ...data,
          operationName: OPERATION_ADD_PART_REQUEST_NAME,
          operationLabel: `Add Part Request for Part ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value}`,
          operationDescription: `Add Part ${data.InventoryID} to Part Request for Task ${currentTask.ProjectTaskID.value}`,
        });
        actions.setAlertThunk({
          message: `Add part request for Part ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value} is now pending. You will need to sync this app once you are online again.`,
          type: "success",
        });
      }

      actions.resetPartQuantityInputAction();
      actions.showAddPartRequestModalAction(false);
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  addUnserviceableStockItemThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const {
      part: { unserviceableStockItemInput },
      task: { currentTask },
      status: { hasWifi },
      employee: { scannedEmployee },
    } = helpers.getState();

    try {
      const data = {
        ...unserviceableStockItemInput,
        Uom: "EA",
        ReqType: "I",
        TaskID: currentTask.ProjectTaskID.value,
        WorkOrderID: currentTask.WorkOrderID.value,
        RequestedBy: scannedEmployee.AcctCD,
        CompWHSE: currentTask.CompanyWarehouse.value,
        CustWHSE: currentTask.CustomerWarehouse.value,
      };

      if (hasWifi) {
        const stockItem = await addUnserviceableStockItemApiCall(data);
        actions.setChosenStockItemAction({
          InventoryCD: stockItem.InventoryCD.value,
          InventoryID: stockItem.InventoryID.value,
          Description: stockItem.Description.value,
        });
        actions.showAddUnserviceablePartRequestModalAction(true);
      } else {
        actions.addPendingOperationAction({
          ...data,
          operationName: OPERATION_ADD_UNSERVICEABLE_STOCK_ITEM_NAME,
          operationLabel: `Add Unserviceable Stock Item ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value}`,
          operationDescription: `Add unserviceable stock item ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value}`,
        });
        actions.setAlertThunk({
          message: `Add Unserviceable Stock Item ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value} is now pending. You will need to sync this app once you are online again.`,
          type: "success",
        });
      }

      actions.showAddUnserviceableStockItemModalAction(false);
      actions.resetUnserviceableStockItemInputAction();
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
  addStockItemRequestThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    const {
      part: { unserviceableStockItemInput, partQuantityInput },
      task: { currentTask },
      status: { hasWifi },
      employee: { scannedEmployee },
    } = helpers.getState();

    try {
      const data = {
        InventoryID: unserviceableStockItemInput.InventoryID,
        Description: unserviceableStockItemInput.Description,
        note: unserviceableStockItemInput.note,
        Qty: partQuantityInput.Qty,
        UsrPriorityLevel: partQuantityInput.UsrPriorityLevel,
        UsrNeedBy: partQuantityInput.UsrNeedBy,
        ReqType: "I",
        TaskID: currentTask.ProjectTaskID.value,
        WorkOrderID: currentTask.WorkOrderID.value,
        RequestedBy: scannedEmployee.AccountID,
        AcctCD: scannedEmployee.AcctCD,
      };

      data.UsrNeedBy && (data.UsrNeedBy = data.UsrNeedBy);

      currentTask.UsrZoneID &&
        currentTask.UsrZoneID.value &&
        (data.UsrZoneID = currentTask.UsrZoneID.value);

      if (hasWifi) {
        const partRequestList = await addStockItemRequestApiCall(data);
        actions.setPartRequestListAction(partRequestList);
        !partRequestList.length && (await actions.getPartRequestsThunk());
      } else {
        actions.addPendingOperationAction({
          ...data,
          operationName: OPERATION_ADD_STOCK_ITEM_REQUEST_NAME,
          operationLabel: `Add Stock Item Request ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value}`,
          operationDescription: `Add stock item request ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value}`,
        });
        actions.setAlertThunk({
          message: `Add Stock Item Request ${data.InventoryID} for Task ${currentTask.ProjectTaskID.value} is now pending. You will need to sync this app once you are online again.`,
          type: "success",
        });
      }

      actions.showAddStockItemRequestModalAction(false);
      actions.resetUnserviceableStockItemInputAction();
      actions.resetPartQuantityInputAction();
      actions.setPartSearchInputAction("");
    } catch (err) {
      console.error(err);
      actions.setAlertThunk({
        type: "error",
        message: handleAcumaticaError(err),
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
