import { useStoreActions, useStoreState } from "easy-peasy";
import Environment from "../../presentational/Environment";
import { setAuthToken } from "../../../utilities/api";

function EnvironmentUsList(props) {
  const { isTest } = useStoreState((state) => state.employee);

  const {
    setUserAction,
    setTenantAction,
    setLogoAction,
    setFaviconAction,
    setSpinnerAction,
  } = useStoreActions((actions) => actions);

  function setGeography(geo) {
    let token = "";
    let logo = "";
    let tenant = "sts";
    let email = "";

    if (isTest) {
      if (geo === "us") {
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InVzMjFSMlRlc3RAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM4NDV9.U2X0aed-H8NVq6IZIX8pHGXG-K0EeruRZyRVsDW7knQ";
        logo = "logoModCenterTest21R2.png";
        email = "us21R2Test@cfbs-us.com";
      } else if (geo === "usQA") {
        tenant = "STS Aviation Group";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTUUFAY2Zicy11cy5jb20iLCJpYXQiOjE2NDkxNjM4NDV9.oukgZx1i9U4mKIa7VTooXOsZ5Yn0skMFEuR8DPm61CQ";
        logo = "logoModCenterQA.png";
        email = "stsUSQA@cfbs-us.com";
      } else if (geo === "usSB") {
        tenant = "STS Aviation Group";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTU2FuZGJveEBjZmJzLXVzLmNvbSIsImlhdCI6MTY0OTE2Mzg0NX0.MBbQytS4YCv2rLvct8FJM0P69x0V5F6O7gwVo-8hb14";
        logo = "logoModCenterSandbox.png";
        email = "stsUSSandbox@cfbs-us.com";
      } else if (geo === "usAL") {
        tenant = "STS Aviation Group";
        token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTQWxwaGFAY2Zicy11cy5jb20ifQ.C7iloz6pB9kSJPTaDJX13ppmIaf0K_ulmEpDFh7ZXgo";
        logo = "logoModCenterUSAlpha.png";
        email = "stsUSAlpha@cfbs-us.com";
      }
    } else {
      token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InN0c1VTUHJvZHVjdGlvbkBjZmJzLXVzLmNvbSIsImlhdCI6MTYzMDI4NTE3Mn0.YiPwt8H-tcy4WNBIy3PhlKbzNfqlUp0R2bPCz8-KaLo";
      logo = "logoModCenter.png";
      email = "stsUSProduction@cfbs-us.com";
    }

    setUserAction({ email });
    setTenantAction(tenant);
    setLogoAction(logo);
    setAuthToken(token);
    setFaviconAction("favicon_sts.ico");
    setSpinnerAction("rotate_sts.gif");
  }

  return (
    <Environment.List
      list={(isTest
        ? [
            {
              environment: "TEST",
              geographyLabel: "US - STS Mod Center",
              geographyValue: "us",
            },
            {
              environment: "QA",
              geographyLabel: "US - STS Mod Center",
              geographyValue: "usQA",
            },
            {
              environment: "SANDBOX",
              geographyLabel: "US - STS Mod Center",
              geographyValue: "usSB",
            },
            {
              environment: "ALPHA",
              geographyLabel: "US - STS Mod Center",
              geographyValue: "usAL",
            },
          ]
        : [
            {
              environment: "",
              geographyLabel: "US - STS Aviation Services",
              geographyValue: "",
            },
          ]
      ).map(({ geographyLabel, geographyValue, ...environment }) => ({
        geography: geographyLabel,
        onPress: () => setGeography(geographyValue),
        ...environment,
      }))}
      {...props}
    />
  );
}

export default EnvironmentUsList;
